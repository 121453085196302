import React from 'react';
import { useBi, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupListLoaded } from '@wix/bi-logger-groups/v2';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';
import { groupsScreenMap } from 'common/hooks/useBiParams';
import { COMPONENT, LayoutType } from 'settings/consts';
import settingsParams from 'Groups/settingsParams';

import { Container } from 'wui/Container';
import type { IControllerProps } from 'controller/types';

import { Layout } from './Layout';
import { CreateGroupDialog } from './CreateGroupDialog';

import classes from './styles.scss';

COMPONENT.name = 'GroupsPage';

const Widget: React.FC<WidgetProps<IControllerProps>> = (props) => {
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const settings = useSettings();

  React.useEffect(() => {
    bi.report(
      groupListLoaded({
        layout_type:
          groupsScreenMap[
            settings.get(settingsParams.groupListLayout) as LayoutType
          ],
      }),
    );
  }, []);

  return (
    <SocialGroupsApp {...props} className={classes.root}>
      <Container fluid={isMobile}>
        <Layout />
        <CreateGroupDialog />
      </Container>
    </SocialGroupsApp>
  );
};

Widget.displayName = 'GroupListPage';

export default Widget;
