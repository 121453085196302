import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { LayoutType } from 'settings/consts';
import { Box } from 'wui/Box';
import { type TPAComponentProps } from 'wui/types';

import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';

import { Groups } from './Groups';

import { FeedWithTitle } from '../FeedWithTitle';

export function GridLayout(props: TPAComponentProps) {
  const { isMobile } = useEnvironment();

  return (
    <Box gap={isMobile ? 'SP0' : 'SP6'} direction="vertical" {...props}>
      <Header />
      <PendingGroups />
      <Groups />
      <FeedWithTitle />
    </Box>
  );
}

GridLayout.displayName = 'GridLayout';
